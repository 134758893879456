import Vue from 'vue'

import { rtdbPlugin } from 'vuefire'
import VueSmoothScroll from 'vue2-smooth-scroll'
// import VueGtag from "vue-gtag";

import './styles/my-styles.scss'

import App from './App.vue'

Vue.use(rtdbPlugin)
Vue.use(VueSmoothScroll)

import firebase from 'firebase/app'
import '@firebase/analytics'
import '@firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCI2VeJjYSSUc-JTU-kK8D-jSHvQGc1KEs",
  authDomain: "appscontrini.firebaseapp.com",
  projectId: "appscontrini",
  storageBucket: "appscontrini.appspot.com",
  messagingSenderId: "596440661132",
  appId: "1:596440661132:web:39fa80ad896bd9a00f0fa9",
  measurementId: "G-V6BSR4J9YM"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false

// alias
Vue.prototype.$analytics = firebase.analytics();


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
 