<template>
  <div class="heavy-rain-gradient">
     <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav" :class="{scrolling: scrollPosition > 0}">
    <div class="container">
      <a class="navbar-brand" v-smooth-scroll href="#page-top">
       <img style="height: 60px;" src="@/assets/images/logo-transparent.png" />
      </a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" v-smooth-scroll href="#download">Download</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " href="https://www.lotteriadegliscontrini.gov.it">Sito ufficiale</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="/files/Locandina.pdf">Scarica locandina</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

    <section id="page-top" style="padding: 0px;"/>

  <header class="masthead">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-lg-7 my-auto">
          <div class="header-content mx-auto">
            <h1 class="mb-5">L'unica App completamente dedicata alla Lotteria Nazionale degli Scontrini!</h1>
            <a href="#download" class="btn btn-outline btn-xl" v-smooth-scroll>Provala subito</a>
          </div>
        </div>
        <div class="col-lg-5 my-auto">
          <div class="device-container">
            <div class="device-mockup iphone6_plus portrait white">
              <div class="device">
                <div class="screen">
                  <!-- Demo image for screen mockup, you can put an image here, some HTML, an animation, video, or anything else! -->
                  <img src="@/assets/images/screen-content-1.png" class="img-fluid" alt="">
                </div>
                <div class="button">
                  <!-- You can hook the "home button" to some JavaScript events or just remove it -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="download bg-primary text-center" id="download">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <h2 class="section-heading">Il tuo Codice Lotteria sempre con Te!</h2>
          <p>AppScontrini è disponibile sia per Android che per iOS. Scaricala ora e memorizza il tuo codice lotteria!</p>
          <div class="badges">
            <a class="badge-link mr-1" href="/app"><img src="@/assets/images/google-play-badge.svg" alt=""></a>
            <a class="badge-link ml-1" href="/app"><img src="@/assets/images/app-store-badge.svg" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features" id="features">
    <div class="container">
      <div class="section-heading text-center">
        <h2>Semplice e funzionale</h2>
        <p class="text-muted">Con AppScontrini potrai accedere facilmente al sito governativo per generare il tuo codice lotteria</p>
        <hr>
      </div>
      <div class="row">
        <div class="col-lg-4 my-auto">
          <div class="device-container">
            <div class="device-mockup iphone6_plus portrait white">
              <div class="device">
                <div class="screen">
                  <!-- Demo image for screen mockup, you can put an image here, some HTML, an animation, video, or anything else! -->
                  <img src="@/assets/images/screen-content-2.png" class="img-fluid" alt="">
                </div>
                <div class="button">
                  <!-- You can hook the "home button" to some JavaScript events or just remove it -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 my-auto">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6">
                <div class="feature-item">
                  <i class="icon-screen-smartphone text-primary"></i>
                  <h3>Crealo subito</h3>
                  <p class="text-muted">Verrai trasferito sul sito ufficiale per la generazione dei codici lotteria</p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="feature-item">
                  <i class="icon-camera text-primary"></i>
                  <h3>Di cosa hai bisogno?</h3>
                  <p class="text-muted">Tieni a portata di mano il tuo codice fiscale se non lo ricordi</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="feature-item">
                  <i class="icon-present text-primary"></i>
                  <h3>Se disinstallo l'app?</h3>
                  <p class="text-muted">Non preoccuparti, potrai reinstallarla e generare un nuovo codice lotteria quando vorrai</p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="feature-item">
                  <i class="icon-lock-open text-primary"></i>
                  <h3>Ho già il mio codice</h3>
                  <p class="text-muted">Se hai già un codice lotteria lo potrai inserire manualmente e salvarlo sul tuo dispositivo</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!-- 
  <section class="cta">
    <div class="cta-content">
      <div class="container">
        <h2>Stop waiting.<br>Start building.</h2>
        <a href="#contact" class="btn btn-outline btn-xl v-smooth-scroll">Let's Get Started!</a>
      </div>
    </div>
    <div class="overlay"></div>
  </section>

  <section class="contact bg-primary" id="contact">
    <div class="container">
      <h2>We
        <i class="fas fa-heart"></i>
        new friends!</h2>
      <ul class="list-inline list-social">
        <li class="list-inline-item social-twitter">
          <a href="#">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
        <li class="list-inline-item social-facebook">
          <a href="#">
            <i class="fab fa-facebook-f"></i>
          </a>
        </li>
        <li class="list-inline-item social-google-plus">
          <a href="#">
            <i class="fab fa-google-plus-g"></i>
          </a>
        </li>
      </ul>
    </div>
  </section> -->

  <footer>
    <div class="container">
      <p>&copy; AppScontrini.it 2020. Tutti i diritti riservati.</p>
      <ul class="list-inline">
        <li class="list-inline-item">
          <a href="#">Privacy</a>
        </li>
        <li class="list-inline-item">
          <a href="#">Termini</a>
        </li>
        <li class="list-inline-item">
          <a href="#">FAQ</a>
        </li>
      </ul>
    </div>
  </footer>
<!-- 
    <div class="page-header">
      <h1>Benvenuto in AppScontrini</h1>
    </div>
    <div class="page-subheader">
      <h4>L'app per la registrazione e memorizzazione del codice Lotteria Nazionale degli Scontrini</h4>
    </div>

<div class="container">
<div class="media">
  <div class="media-body align-self-center">
    <h4>Scaricala subito</h4>
    <ul class="list-unstyled">
      <li><strong>Gratis</strong></li>
      <li><strong>Sicura</strong></li>
      <li><strong>Semplice da usare</strong></li>
    </ul>

    <a class="btn btn-primary" href="/app" role="button">Scaricala ora</a>
    <h4 class="mt-4">Sei un esercente e vuoi stampare la locandina per la tua attività</h4>
    <a class="btn btn-warning" href="/files/Locandina.pdf" role="button">Scarica la locandina</a>
  </div>
  
  <div class="col-md-4" style="padding-left: 0px;  padding-right: 0px;">
    <img class="img-responsive ml-3 img-fluid" src="@/assets/images/screen1.png" />
  </div>
</div>
</div>

    <div class="card mt-4"> 
      <div class="card-header">
        <h3 class="card-title text-center mb-0">Che cos'è?</h3>
      </div>
         <div class="p-3">
           La Lotteria degli scontrini è una <b>lotteria nazionale gratuita</b> che prevede <b>etrazioni settimanali, 
             mensili e annuali</b>.
         </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <h3 class="card-title text-center mb-0">Come funziona?</h3>
      </div>
         <div class="p-3">
      Se sei una persona <b>maggiorenne residente in Italia</b> e partecipi alla Lotteria, a partire dal 2021 i tuoi scontrini
    collegati a normali acquisti effettuati genereranno automaticamente
    <b>biglietti virtuali della Lotteria che potranno far vincere premi a te e agli esercenti che li hanno emessi</b>.
    </div>
    </div> 

    <div class="card mt-4">
      <div class="card-header">
        <h3 class="card-title text-center mb-0">Come mi iscrivo?</h3>
      </div>
      <div class="p-3">
      <p>Registrarsi è semplice ed hai due modi per farlo.</p>
      Vai al sito ufficiale governativo ed inserisci il tuo codice fiscale per generare il tuo codice lotteria
      oppure scarica la nostra app ed oltre alla registrazione avrai la possibilità di memorizzare sul tuo dispositivo il
        codice lotteria, il codice a barre ed un Qr-Code da portare sempre con te.
      </div>
    </div>


    <div class="mt-4">

    </div> -->

  </div>
</template>

<script>
// import Slogan from './components/Slogan';
// import Firebase from 'firebase'

// let config = {
//   apiKey: process.env.VUE_APP_API_KEY,
//   authDomain: process.env.VUE_APP_AUTH_DOMAIN,
//   databaseURL: process.env.VUE_APP_DATABASE_URL,
//   projectId: process.env.VUE_APP_PROJECT_ID,
//   storageBucket: "",
//   messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_APP_ID
// }

// let app = Firebase.initializeApp(config)
// let db = app.database()

export default {
  name: 'app',
  // firebase: {
  //   books: db.ref('books')
  // },
  data() {
    return {
      scrollPosition: 0
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleTopBar);
  },
  methods: {
    handleTopBar() {
      this.scrollPosition = window.scrollY
    }
  }
  //   addBook() {
  //     db.ref('books').push(this.newBook)
  //     this.newBook = {
  //       title: '',
  //       author: '',
  //       url: ''
  //     }
  //   },
  //   deleteBook(book) {
  //     db.ref('books').child(book['.key']).remove()
  //   }
  // },
}
</script>

<style>
#mainNav.scrolling{
  background-color:#2a5298
}
</style>
